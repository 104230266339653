



























































import { Component, Mixins } from 'vue-property-decorator';
import { ProfileFilter } from 'client-website-ts-library/filters';
import { Profile, WebsiteLevel, WebsiteProfileDisplayType } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';

import ProfileCards from '../components/ProfileCards.vue';
import Loader from '../components/UI/Loader.vue';

@Component({
  components: {
    ProfileCards,
    Loader,
  },
})
export default class People extends Mixins(View) {
  private profiles: Profile[] = [];

  private corporate: Profile[] = [];

  mounted() {
    const filter = new ProfileFilter();

    API.Profiles.Search(filter, true).then((profiles) => {
      this.profiles = profiles;
    });

    filter.SearchGuid = 'b6b5cc8c-42db-4fea-884a-f494ae7a98d3';
    filter.SearchLevel = WebsiteLevel.Office;

    API.Profiles.Search(filter).then((profiles) => {
      this.corporate = profiles;
    });
  }

  get directors(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Principal
    && (p.Id !== '42a8d902-1f58-4d82-aa3b-a4808f3e2914' && p.Id !== 'ee64d590-e007-4e19-9886-495c7ce7d35b' && p.Id !== 'f6c0d3f8-a05b-4f0c-aed8-0e913aeca0de') && p.Office.Id !== 'b6b5cc8c-42db-4fea-884a-f494ae7a98d3');
  }

  get commercialAgents(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.CommercialAgent && (p.Id !== '42a8d902-1f58-4d82-aa3b-a4808f3e2914' && p.Id !== 'ee64d590-e007-4e19-9886-495c7ce7d35b') && p.Office.Id !== 'b6b5cc8c-42db-4fea-884a-f494ae7a98d3');
  }

  get salesAgents(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Agent && (p.Id !== '42a8d902-1f58-4d82-aa3b-a4808f3e2914' && p.Id !== 'ee64d590-e007-4e19-9886-495c7ce7d35b') && p.Office.Id !== 'b6b5cc8c-42db-4fea-884a-f494ae7a98d3');
  }

  get propertyManagers(): Profile[] {
    return this.profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.PropertyManager && p.Office.Id !== 'b6b5cc8c-42db-4fea-884a-f494ae7a98d3');
  }

  get support(): Profile[] {
    return this.profiles.filter((p) => (p.DisplayType === WebsiteProfileDisplayType.PersonalAssistant || p.DisplayType === WebsiteProfileDisplayType.Support) && p.Office.Id !== 'b6b5cc8c-42db-4fea-884a-f494ae7a98d3');
  }
}
